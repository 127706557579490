@import '../../../../global.scss';

.contact {
  background: linear-gradient(180deg, $backgroundColor2 0%, $backgroundColor1 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: calc(100dvh - 70px);
  h1 {
    color: white;
    // padding: 25px 25px;
    text-align: center;
    font-family: $specialFont;
  }
  .container{
    display: flex;
    justify-content: space-evenly;
    width: 100vw;
    align-items: center;
    flex-direction: row;
    &.mobile {
      flex-direction: column;
    }
    .right{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h2{
        color: white;
        // padding: 25px 25px;
        text-align: center;
        font-family: $specialFont;
        a {
          // padding: 25px 25px;
          text-align: center;
          font-family: $specialFont;
        }
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        
        .icon {
          margin-top: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}