@import '../../../../global.scss';

.menu {
  width: 100vw;
  height: 100dvh;
  top: 0;
  position: fixed;
  left: -100vw;
  z-index: 1000;
  transition: all 0.5s ease;

  &.active {
    left: 0;
  }

  .container {
    width: 100vw;
    height: 100dvh;
    display: flex;

    .spacer {
      width: 50px;
      background-color: $mainColor;
    }

    ul {
      width: 600px;
      margin: 0;
      padding: 0;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      list-style: none;
      background-color: $mainColor;
      top: 0;
      font-size: 30px;
      font-weight: 300;
      color: white;
      border-right: 1px solid $accentColor;
      border-radius: 0 20% 20% 0;
      .language-container{
        display: flex;
        align-items: center;
        justify-content: right;
        width: 100%;
        padding-bottom: 40px;
        .language{
          padding-right: 20px;
          width: 120px;
          >* {
            color: white;
          }
        }
      }
      li {
        margin-bottom: 20px;

        &:hover {
          color: $accentColor;
          cursor: pointer;
          transform: translate(10px);
          font-weight: 600;

          >* {
            cursor: pointer;
            color: $accentColor;
          }
        }

        .icon {
          margin-right: 10px;
        }

        a {
          font-size: inherit;
          color: $mainColor;
          text-decoration: none;
          // transition: color 1s ease;
          color: white;
        }
      }
    }

    .closer {
      width: 100%;
    }
  }
}