@import '../../../../global.scss';

.topbar {
  margin-bottom: 1px;
  width: 100%;
  height: 70px;
  background-color: white;

  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 1001;
  transition: all 0.5s ease;
  &.mobile {
    height: 50px;
  }
  .wrapper {
    padding: 10px 30px;
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: space-between;
    &.mobile {
      padding: 2.5px 10px;
    }
    .left {
      .menuButton {
        font-size: 40px;
        cursor: pointer;
        &:hover {
          color: $accentColor;
        }
      }
      .closeButton {
        font-size: 40px;
        cursor: pointer;
        &:hover {
          color: $accentColor;
        }
      }
    }
    .middle {
      .logo {
        font-family: $logoFont;
        font-size: 30px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        // margin-right: 40px;
        &:hover {
          color: $accentColor;
        }
      }
      .small-logo {
        font-family: $logoFont;
        font-size: 25px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;

        // margin-right: 40px;
        &:hover {
          color: $accentColor;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;

      > * {
        font-size: 30px;
        cursor: pointer;
        &:hover {
          color: $accentColor;
        }
      }
    }
  }
  &.active {
    background-color: $mainColor;
    color: white;
  }
}
