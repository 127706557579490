@import '../../global.scss';

.customerUI {
  height: 100dvh;
  
  >* {
    scroll-behavior: smooth;
  }
  .sections {
    width: 100%;
    height: calc(100dvh - 70px);
    position: relative;
    top: 70px;

    scrollbar-width: none; // for firefox
    &.mobile {
      height: calc(100dvh - 50px);
      top: 50px;
    }
    &::-webkit-scrollbar {
      // for all the other broswers
      display: none;
    }

    >* {
      border-bottom: 1px solid $accentColor;
      width: 100%;
      // height: calc(100dvh - 70px);

    }
  }
}