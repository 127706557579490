@import '../../../../global.scss';

.gallery {
  background: linear-gradient(180deg, $backgroundColor1 0%, $backgroundColor2 100%);
  height: 100dvh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  &.mobile {
    height: calc(78dvh - 50px);
  }

  h1 {
    color: white;
    padding-bottom: 10px;
    text-align: center;
    font-family: $specialFont;
  }

  .container {
    width: 80vw;
    height: 70dvh;
    position: relative;
    padding: 0;
    margin: 0;

    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: row;
    &.mobile {
      width: 95vw;
      height: 50dvh;
    }

    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-self: center;
      flex-direction: row;

      .centerImage {
        object-fit: contain;
        width: 80%;
        transition: all 1000ms ease;
        
        &.mobile {
          width: 95%;
        }
      }

      .prev,
      .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: 50px;
        fill: white;
        // padding: 16px;
          // color: aqua;
          // font-weight: bold;
          // font-size: 18px;
          transition: 0.6s ease;
          user-select: none;
        &.mobile {
          top: 46%
        }
        &:hover {
          fill: $accentColor;
        }
      }

      .prev {
        left: 0;
        // border-radius: 0 3px 3px 0;
      }

      .next {
        right: 0;
        // border-radius: 3px 0 0 3px;
      }
    }

    .fullscreen {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30px;
      height: 30px;
      fill: white;
      // padding: 16px;
      // color: aqua;
      // font-weight: bold;
      // font-size: 18px;
      transition: 0.6s ease;
      user-select: none;
    }
    .counter {
      display: flex;
      justify-content: center;
      align-self: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 20px;
      color: white;
      padding: 10px 20px;
      background-color:rgba(0, 0, 0, .4);
      transition: 0.6s ease;
      user-select: none;
    }
  }
    
  .list {
    display: flex;
    align-self: center;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 98vw;
    .icon{
      width: 150px;
      height: 100px;
      border: 2px solid rgba(255, 255, 255, 0);
       &.selected {
        border: 2px solid $accentColor;
        width: 165px;
        height: 110px;
      }
      &:hover {
        border: 2px solid white;
      }
    }
    

    .thumbnail {
      image-rendering: -webkit-optimize-contrast;
      cursor: pointer;
      flex-shrink: 0;
      display: inline;
      width: 150px;
      height: 100px;
      margin: 0px 2px;
      border: 2px solid rgba(255, 255, 255, 0);
      &.selected {
        border: 2px solid $accentColor;
        width: 165px;
        height: 110px;
      }
      &:hover {
        border: 2px solid white;
      }
      &.mobile {
        width: 120px;
        height: 80px;
        margin: 0px 2px;
        &.selected {
          border: 2px solid $accentColor;
          width: 132px;
          height: 88px;
        }
      }
            
    }
    
  }

}