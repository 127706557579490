@import '../../../../global.scss';
$blur: 0px;

.intro {
  height: calc(100dvh - 70px);
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  &.mobile {
    height: calc(100dvh - 50px);
  }
  .image{
    height: 100%;
    width: 100%;
    background: url("../../../../../public/assets/gallery/MANTHOS-01.webp");
    background-size: cover;
    background-position: center;
    -webkit-filter: blur($blur); //change the value according to what suits the best
    -moz-filter: blur($blur); //change the value according to what suits the best
    -o-filter: blur($blur); //change the value according to what suits the best
    -ms-filter: blur($blur); //change the value according to what suits the best
    filter: blur($blur); //change the value according to what suits the best
    &.mobile {
      background: url("../../../../../public/assets/intro_photo_mobile.webp");
      background-size: cover;
      background-position: center;
      // background-position: 55%;
    }
  }
  .content {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .box{
      position: absolute;
      top: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-weight: bold;
      border: 3px solid #f1f1f1;
      border-radius: 10px;
      padding: 15px;
      margin: 10px;
      
      &.mobile {
        top: 5%;
      }
      h1 {
        color: white;
        padding: 25px 25px;
        text-align: center;
        font-family: $specialFont;
        font-size: 40px;
        &.mobile {
          font-size: 30px;
        }
      }
      .buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        .button {
          margin: 5px 5px;
        }
      }
      
    }
    .arrowbutton {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .arrow {
        color: $accentColor;
        font-size: 125px;
        animation: arrowBlink 2s infinite;
        &.mobile {
          font-size: 100px;
        }
      }

      @keyframes arrowBlink {
        0% {
          opacity: 0.3;
        }

        50% {
          opacity: 1;
        }

        100% {
          opacity: 0.3;
        }
      }
    }
  }
}
