$mainColor: #184e77;
$accentColor: #e7a113;

$listColor1: #1f5a88;
$listColor2: #184e77;

$backgroundColor1: #2F66AB;
$backgroundColor2: #30849b;
$backgroundColor3: #3EACC3;

$quoteFont: 'IM Fell French Canon', serif;
// $logoFont: 'Italianno', cursive;
$logoFont: 'Allura', cursive;
// $specialFont: 'Playfair Display', serif;
$specialFont: 'PT Serif', serif;
