@import '../../../../global.scss';

.informations {
  background: linear-gradient(180deg, $backgroundColor2 0%, $backgroundColor3 100%);

  h1 {
    color: white;
    // padding: 25px 25px;
    text-align: center;
    font-family: $specialFont;
  }
// .list{
//   padding: 100px;
// }
  h2, h3 {
    color: white;
    padding: 20px;
    text-align: center;
    font-family: $specialFont;
  }
  .categories{
    padding: 10px;
    li {
      padding: 0px 10px;
    }
    .text {
      .MuiListItemText-secondary {
        color: white;
        font-size: 16px;
      }
      > * {
        font-family: $specialFont;
        font-size: 18px;
        color: $accentColor;
      }
    }

    .color1 {
      background-color: $listColor1;
    }
  
    .color2 {
      background-color: $listColor2;
    }
  }

}